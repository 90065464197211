const windLevel = (speed: any) => {
    let level = 0;
    if (speed <= 0.2) {
        level = 0;
    } else if (speed > 0.2 && speed <= 1.5) {
        level = 1;
    } else if (speed > 1.5 && speed <= 3.3) {
        level = 2;
    } else if (speed > 3.3 && speed <= 5.4) {
        level = 3;
    } else if (speed > 5.4 && speed <= 7.9) {
        level = 4;
    } else if (speed > 7.9 && speed <= 10.7) {
        level = 5;
    } else if (speed > 10.7 && speed <= 13.8) {
        level = 6;
    } else if (speed > 13.8 && speed <= 17.1) {
        level = 7;
    } else if (speed > 17.1 && speed <= 20.7) {
        level = 8;
    } else if (speed > 20.7 && speed <= 24.4) {
        level = 9;
    } else if (speed > 24.4 && speed <= 28.4) {
        level = 10;
    } else if (speed > 28.4 && speed <= 32.6) {
        level = 11;
    } else if (speed > 32.6 && speed <= 36.9) {
        level = 12;
    }
    return level;
}
const windDirection = (idirection: any) => {
    let windeDirection;
    if (0 <= idirection && idirection < 45) {
        windeDirection = '北';
    } else if (45 <= idirection && idirection < 90) {
        windeDirection = '东北';
    } else if (90 <= idirection && idirection < 135) {
        windeDirection = '东';
    } else if (135 <= idirection && idirection < 180) {
        windeDirection = '东南';
    } else if (180 <= idirection && idirection < 225) {
        windeDirection = '南';
    } else if (225 <= idirection && idirection < 270) {
        windeDirection = '西南';
    } else if (270 <= idirection && idirection < 315) {
        windeDirection = '西';
    } else if (315 <= idirection && idirection <= 360) {
        windeDirection = '西北';
    } else {
        windeDirection = '未知';
    }
    return windeDirection;
}
const windSixteenDirection = (idirection: any) => {
    let windeDirection;
    let windDirectionName;
    if ((0 <= idirection && idirection < 11.25) || (348.75 <= idirection && idirection < 360)) {
        windeDirection = 'N';
        windDirectionName = '北'
    } else if (11.26 <= idirection && idirection < 33.75) {
        windeDirection = 'NNE';
        windDirectionName = '北东北'
    } else if (33.76 <= idirection && idirection < 56.25) {
        windeDirection = 'NE';
        windDirectionName = '东北'
    } else if (56.26 <= idirection && idirection < 78.75) {
        windeDirection = 'ENE';
        windDirectionName = '东东北'
    } else if (78.76 <= idirection && idirection < 101.25) {
        windeDirection = 'E';
        windDirectionName = '东'
    } else if (101.26 <= idirection && idirection < 123.75) {
        windeDirection = 'ESE';
        windDirectionName = '东东南'
    } else if (123.76 <= idirection && idirection < 146.25) {
        windeDirection = 'SE';
        windDirectionName = '东南'
    } else if (146.26 <= idirection && idirection <= 168.75) {
        windeDirection = 'SSE';
        windDirectionName = '南东南'
    } else if (168.76 <= idirection && idirection <= 191.25) {
        windeDirection = 'S';
        windDirectionName = '南'
    } else if (191.26 <= idirection && idirection <= 213.75) {
        windeDirection = 'SSW';
        windDirectionName = '南西南'
    } else if (213.76 <= idirection && idirection <= 236.25) {
        windeDirection = 'SW';
        windDirectionName = '西南'
    } else if (236.26 <= idirection && idirection <= 258.75) {
        windeDirection = 'WSW';
        windDirectionName = '西西南'
    } else if (258.76 <= idirection && idirection <= 281.25) {
        windeDirection = 'W';
        windDirectionName = '西'
    } else if (281.26 <= idirection && idirection <= 303.75) {
        windeDirection = 'WNW';
        windDirectionName = '西西北'
    } else if (303.76 <= idirection && idirection <= 326.25) {
        windeDirection = 'NW';
        windDirectionName = '西北'
    } else if (326.26 <= idirection && idirection <= 348.75) {
        windeDirection = 'NNW';
        windDirectionName = '北西北'
    } else {
        windeDirection = '未知';
        windDirectionName = '未知'
    }
    return {windeDirection, windDirectionName};
}
export default {windLevel, windDirection, windSixteenDirection}
