import moment from 'moment'
const getOption = (data1: number[], data2: number[]): any => {
    const fontSize = 12
    return {
        animation: false,
        grid: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        xAxis: {
            show: false,
            type: 'category',
            data: data1,
            axisLine: {
                lineStyle: {
                    color: '#FFFFFF'
                },
            },
        },
        // dataZoom: [{ 
        //     type: 'inside',
        //     start: 0,
        //     end: 100,
        // }],
        yAxis: [{
            show: false,
            name: '温度/℃',
            type: 'value',
            min: (value: any) => Math.round(value.min - 1),
            max: (value: any) => Math.round(value.max + 1),
            splitNumber: 1,
            splitLine: { show: false},
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#FFFFFF'
                },
            },
        }, {
            show: false,
            name: '降水/mm',
            type: 'value',
            min: 'dataMin',
            max: 'dataMax',
            splitNumber: 1,
            splitLine: { show: false},
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#FFFFFF'
                },
            },
        }],
        series: [{
            data: data2,
            symbol: 'emptyCircle',
            symbolSize: 5,
            smooth: true,
            label: {
                show: false,
                color: '#FFB20C',
                fontSize,
            },
            itemStyle: {
                color: '#FFB20C',
            },
            type: 'line',
            yAxisIndex: 0,
        // }, {
        //     data: data2,
        //     itemStyle: {
        //         color: '#1286FC',
        //     },
        //     type: 'bar',
        //     yAxisIndex: 1,
        }]
    }
}
export default getOption