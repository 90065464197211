













import Vue from 'vue'
import cyFormat from '@/utils/cyFormat'
import skyconText from '@/utils/skyconText'
import moment from 'moment'
export default Vue.extend({
    props: {
        data: {type: Object},
    },
    watch: {
        data() {
            this.getData()
        },
    },
    data() {
        return {
            daysData: [],
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            // console.log(this.data)
            const tempData = this.data.temperature
            const skycon = this.data.skycon
            const daysData = skycon.map((e: any) => {
                return {
                    imgUrl: `/static/weatherIcon/${e.value}.png`,
                    skyconText: skyconText.skyconText(e.value),
                    date: e.date.split('T')[0].split('-').slice(1, 3).join('/'),
                }
            })
            daysData.map((e: any) => {
                tempData.map((k: any) => {
                    if (e.date === k.date.split('T')[0].split('-').slice(1, 3).join('/')) {
                        e.min = (k.min).toFixed(0)
                        e.max = (k.max).toFixed(0)
                    }
                })
            })
            // this.daysData = daysData
            this.daysData = daysData.slice(1, 8)
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp * 1000)
            return time.format('M/D HH:00')
        }
    }
})
