







import Vue from 'vue'
import Marquee from '@/components/Marquee.vue'
import request from '@/utils/request'
export default Vue.extend({
    props: {
        data: {type: Object},
    },
    components: {
        Marquee,
    },
    data() {
        return {
            // data: [],
            msg: '',
            time: null,
        }
    },
    mounted() {
        // console.log(this.data)
        // this.getMsg()
        if (this.data.content.length > 0) {
            const obj = this.data.content[0] as any
            this.msg = obj.province + obj.description
        }
    },
    methods: {
        getMsg() {
            request.get('/api/tobacco/rest_disease_pests/disease_pests_waring').then(res=> {this.msg = res})
        },
    },
})
