
















































import Vue from 'vue'
import request from '@/utils/request'
import moment from 'moment'

import realtime from './new/realtime.vue'
import hourly from './new/hourly.vue'
import daily from './new/daily.vue'
import info from './new/info.vue'
import areaSelection from './new/areaSelection.vue'

import MyAlert from './alert.vue'
// import Realtime from './realtime.vue'
// import Hourly from './hourly.vue'
// import Daily from './daily.vue'
// import Info from './info.vue'
export default Vue.extend({
    components: {
        realtime,
        hourly,
        daily,
        info,
        areaSelection,
        MyAlert,
        // Realtime,
        // Hourly,
        // Daily,
        // Info,
    },
    computed: {
        areaItem(): any {
            const { areaId, areaList } = this.$store.state
            if (!areaId) return areaList[0]
            for (let i = 0; i < areaList.length; i++) {
                const area = areaList[i]
                if(area.id === areaId) return area
                if (areaId.length > 6) {
                    if(area.id === areaId.slice(0,6)) return area
                }
            }
            return null
        },
        // areaId(): any {
        //     return this.$store.state.areaId
        // }
    },
    watch: {
        // areaItem() {
        //     // this.getData()
        // },
    },
    data() {
        return {
            timer: null as any,
            setIntervalTag: true,
            data:{},
            // index: 0,
            bgImage: '/static/images/bg_w_other.png',
            areaShow: false,
            areaName: '',
            tabactive: 0,
            type: true,
        }
    },
    mounted() {
        const { areaId } = this.$store.state
        if(!this.areaItem) {
            this.areaId = '431024'
            this.areaName = '嘉禾县'
            this.getData('112.384577', '25.593098')
        } else {
            this.areaId = areaId.length > 6 ? areaId.slice(0,6) : areaId
            this.areaName = this.areaItem.name
            const {lon, lat} = this.areaItem
            this.getData(lon, lat)
        }
        this.getLocation()
    },
    methods: {
        getLocation() {
            const that = this
            if (navigator.geolocation) {
                // alert('支持地理定位')
                // console.log(this.setIntervalTag)
                if (this.setIntervalTag === true) {
                    navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // console.log(position)
                        // alert('经度' + position.coords.longitude)
                        // alert('纬度' + position.coords.latitude)
                        const longitude = position.coords.longitude
                        const latitude = position.coords.latitude
                        // const longitude = '112.384577'
                        // const latitude = '25.593098'
                        const params = {
                            postStr: `{'lon':${longitude},'lat':${latitude},'ver':1}`,
                            type: 'geocode',
                            tk: '13c72b545322ec87faf658079794c444',
                        }
                        that.getData(longitude, latitude)
                        request.get('/geocoder', params).then((res) => {
                            // console.log(res)
                            if (res) {
                                const {city, county, road} = res.result.addressComponent
                                let areaName = ''
                                if (!county && !road) {
                                    areaName = '位置信息错误'
                                } else if (!county) {
                                    areaName = road
                                } else if (!road) {
                                    areaName = county
                                } else {
                                    areaName = county + road
                                }
                                that.areaName = areaName
                                this.areaId = 'location'
                            }
                        })
                    },
                    (error) => {
                        // alert('地理定位失败')
                        if (that.setIntervalTag === true) {
                            that.showError(error)
                        }
                        that.setIntervalTag = false
                        that.getLocation()
                    })
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            } else {
                alert('不支持地理定位')
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        changeArea() {
            this.areaShow = true
        },
        getAreaStation(value) {
            // console.log(value)
            if(value) {
                if (value === 'location') {
                    this.getLocation()
                } else {
                    this.areaName = value.areaName
                    this.areaId = value.areaId
                    this.getData(value.lon, value.lat)
                }
            }
            this.areaShow = false
        },
        getData(lon, lat) {
            // if(!this.areaItem) return
            // const {lon, lat} = this.areaItem
            request.get(`/v2.5/realtime?point=${lon},${lat}`)
            // request.get(`/v2.5/0woAfhHWQi4n5X9N/${lon},${lat}/weather.json?hourlysteps=25&dailysteps=8&alert=true`)
            .then(res => {
                // console.log(res.result.alert)
                // this.alert = {
                //     status: 'ok',
                //     content:[{
                //         request_status: "ok",
                //         alertId: "35040041600001_20200421123203",
                //         latlon: [26.263406,117.638678],
                //         pubtimestamp: 1587443583,
                //         status: "预警中",
                //         regionId: "unknown",
                //         adcode: "350400",
                //         location: "福建省三明市",
                //         province: "福建省",
                //         city: "三明市",
                //         county: "无",
                //         code: "0902",
                //         source: "国家预警信息发布中心",
                //         title: "三明市气象台发布雷电黄色预警[Ⅲ级/较重]",
                //         description: "三明市气象台2020年04月21日12时19分继续发布雷电黄色预警信号：预计未来6小时我市有雷电活动，局地伴有短时强降水、6-8级雷雨大风等强对流天气。请注意防范！"
                //     }]
                // }
                this.data = res
                const skycon = res.result.realtime.skycon
                let bgImage = '/static/images/bg_w_other.png'
                if (skycon.indexOf('CLEAR') > -1) {
                    bgImage = '/static/images/bg_w_qing.png'
                } else if (skycon.indexOf('PARTLY') > -1) {
                    bgImage = '/static/images/bg_w_yun.png'
                } else if (skycon.indexOf('CLOUDY') > -1) {
                    bgImage = '/static/images/bg_w_yin.png'
                } else if (skycon.indexOf('RAIN') > -1) {
                    bgImage = '/static/images/bg_w_yu.png'
                } else {
                    bgImage = '/static/images/bg_w_other.png'
                }
                this.bgImage = bgImage
            })
        },
        showError(error: any) {
            switch (error.code) {
                // case error.PERMISSION_DENIED:
                case 1:
                alert('定位失败,没有权限使用地理定位或用户拒绝请求地理定位')
                break;
                // case error.POSITION_UNAVAILABLE:
                case 2:
                alert('定位失败,位置信息不可用，无法确定设备的位置')
                // alert('网络不可用或者无法连接到获取位置信息的卫星')
                break;
                // case error.TIMEOUT:
                case 3:
                alert('定位失败,请求获取用户位置超时')
                break;
                // case error.UNKNOWN_ERROR:
                case 0:
                alert('定位失败,定位系统失效')
                break;
            }
            // error对象的code属性有如下属性值:
            // PERMISSION_DENIED(1):(permission_denied):用户单机信息条上的“不共享”按钮或直接拒绝被获取位置信息
            // POSITION_UNAVAILABLE(2):(position_unavailable):(position_unavailable)网络不可用或者无法连接到获取位置信息的卫星
            // TIMEOUT(3):(timeout)网络可用但在计算机用户的位置上花费过长时间
            // UNKNOWN_ERROR(0):(unknown_error)发生其他未知错误
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp * 1000)
            return time.format('YYYY/MM/DD HH时00分 更新')
        }
    }
})
