















































import Vue from 'vue'
import cyFormat from '@/utils/cyFormat'
import skyconText from '@/utils/skyconText'
import windElement from '@/utils/windElement'
import moment from 'moment'
export default Vue.extend({
    props: {
        data: {type: Object},
    },
    computed: {
        skyconDesc(): string {
            return cyFormat.getSkyconDesc(this.data?.skycon)
            // return skyconText.skyconText(this.data?.skycon)
        },
        skyconIcon(): string {
            return cyFormat.getSkyconIcon(this.data?.skycon)
        },
        windDesc(): string {
            return windElement.windDirection(this.data?.wind.direction)
        },
    },
})
